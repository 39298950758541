import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/bus/iris/pageList", formData);
}

function edit(id){
    return request.get(constant.serverUrl + "/bus/iris/edit/" + id);
}

function update(formModel){  
    return request.post(constant.serverUrl + "/bus/iris/update", formModel,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}

function exportXls(formData) {
    //导出xls
    return request.post(constant.serverUrl + "/bus/iris/exportXls", formData);
}


export default {
    pageList,edit,update,exportXls
}