<template>
        <el-dialog
        width="30%"
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="true"
        :append-to-body="true"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
        >
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" >
                    <el-form-item label="预采集时间" prop="preAcquisitionTime" label-width="100px">
                        <template>
                            <div class="block">
                                <el-date-picker
                                style="width:300px"
                                v-model="formModel.preAcquisitionTime"
                                type="datetime"
                                placeholder="选择预采集时间"
                                value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item label="采集时间" prop="acquisitionTime" label-width="100px">
                        <template>
                            <div class="block">
                                <el-date-picker
                                style="width:300px"
                                v-model="formModel.acquisitionTime"
                                type="datetime"
                                placeholder="选择采集时间"
                                value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item label="采集凭证" prop="acquisitionVoucher" label-width="100px">
                      <el-upload
                        class="avatar-uploader"
                        name="photoFile"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :headers="headers"
                        :data="uploadData"
                        :on-success="handleAvatarSuccess"
                      >
                        <img v-if="fileUrl" :src="fileUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit"  :loading="submitting">确 定</el-button>
            </span>

        </el-dialog>
</template>
<script>
import Constant from "@/constant";
import irisCourseStudentDetail from '@/api/bus/irisCourseStudent'
import { getToken } from "@/utils/auth"; // get token from cookie
export default {
    props: ["businessKey", "title"],
    data() {
        return{
            formModel:{
                id: "",
                preAcquisitionTime:"",
                acquisitionTime:"",
                acquisitionVoucher:"",
            },
            showDialog: true,
            loading: false,
            submitting: false,
            uploadUrl: Constant.serverUrl + "/uploadPicture",
            headers: {
              Authorization: getToken()
            },
            uploadData: {
              subFolder: "irisCourseStudent"
            },
            fileUrl: "",
        }
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit() {
            var self = this;
            this.$refs["form"].validate(valid => {
                if (valid) {
                (function() {
                    var id = self.formModel.id;

                    return irisCourseStudentDetail.update(self.formModel);
                    
                })().then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    self.$message({
                        message: "保存成功!",
                        type: "success"
                    });
                    self.$emit("close", {
                        result: true,
                        data: jsonData.data
                    });
                    } else {
                    self.$message({
                        message: jsonData.message + "",
                        type: "warning"
                    });

                    self.$emit("close", {
                        result: false
                    });
                    }
                });
                }
            });
        },
        handleAvatarSuccess(res, file) {
            var self = this;
            self.formModel.acquisitionVoucher = res.data;
            self.fileUrl =
                res.data + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('上传凭证图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传凭证图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    },
    async mounted() {
        console.log("mounted");

        var self = this;

        (function() {
            return irisCourseStudentDetail.edit(self.businessKey);
        })()
        .then(response => {
          var jsonData = response.data;

          if (jsonData.result) {

            self.formModel = jsonData.data;
            let acquisitionVoucher = self.formModel.acquisitionVoucher;
            if (acquisitionVoucher != null) {
              self.fileUrl =
                acquisitionVoucher + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
            }
          } else {
            self.$message.error(jsonData.message + "");
          }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>


