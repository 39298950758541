import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/bus/registrationFee/pageList", formData);
}

function periodList(){
    return request.post(constant.serverUrl + "/bus/registrationFee/periodList");
}

function schoolClassList(){
    return request.post(constant.serverUrl + "/bus/registrationFee/schoolClassList");
}

function exportXls(formData) {
    //导出xls
    return request.post(constant.serverUrl + "/bus/registrationFee/exportXls", formData);
}

function edit(id){
    return request.get(constant.serverUrl + "/bus/registrationFee/edit/" + id);
}

function update(formData){  
    return request.post(constant.serverUrl + "/bus/registrationFee/update", formData);
}

function batchUpdate(idList){
    return request.post(constant.serverUrl + "/bus/registrationFee/batchUpdate",idList,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}


function batchDelete(idList){
    return request.post(constant.serverUrl + "/bus/registrationFee/batchDelete",idList,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}

export default {
    pageList,periodList,schoolClassList,exportXls,edit,update,batchUpdate,batchDelete
}