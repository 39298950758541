<template>
    <div class="route-list">
        <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="#">增值服务</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="#">虹膜缴费管理</a>
        </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="学校/班级" prop="schoolClassId" label-width="80px">
                <el-cascader
                    v-model="queryModel.schoolClassId"
                    style="width:350px"
                    placeholder="请选择"
                    size="mini"
                    :options="schoolClassList"
                    change-on-select
                ></el-cascader>
            </el-form-item>
            <el-form-item label="学生姓名" prop="name" label-width="80px">
                <el-input v-model="queryModel.name" size="mini"  style="width:193px"/>
            </el-form-item>
            <el-form-item label="性别" prop="sex" label-width="80px">
                <el-select v-model="queryModel.sex" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in sexList"
                        :key="item.value"
                        :label="item.lable"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="缴费时间" prop="payTime" label-width="80px">
                <template>
                    <div class="block">
                        <el-date-picker
                        size="mini"
                        v-model="queryModel.payTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </div>
                </template>
            </el-form-item>
            <el-form-item label="缴费方式" prop="payName" label-width="80px">
                <el-select v-model="queryModel.payName" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in payNameList"
                        :key="item.value"
                        :label="item.lable"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="采集状态" prop="status" label-width="80px">
                <el-select v-model="queryModel.status" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.lable"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="采集时间" prop="acquisitionTime" label-width="80px">
                <template>
                    <div class="block">
                        <el-date-picker
                        size="mini"
                        v-model="queryModel.acquisitionTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </div>
                </template>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                type="info"
                size="mini"
                style="margin-left: 8px"
                @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-download"
            :loading="downloadLoading"
            @click="downloadXls"
        >导出数据</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
            >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="name" label="姓名" fixed="left"></el-table-column>
            <el-table-column prop="schoolName" label="学校" fixed="left"></el-table-column>
            <el-table-column prop="className" label="班级" fixed="left"></el-table-column>
            <el-table-column prop="idCard" label="身份证" fixed="left" ></el-table-column>
            <el-table-column prop="sex" label="性别" fixed="left"></el-table-column>
            <el-table-column prop="payTime" label="缴费时间" fixed="left"></el-table-column>
            <el-table-column prop="payName" label="缴费方式" fixed="left"></el-table-column>
            <el-table-column prop="preAcquisitionTime" label="预采集时间" fixed="left"></el-table-column>
            <el-table-column prop="status" label="采集状态" fixed="left">
                <template slot-scope="{row}">
                    <span v-if="row.status=='20'" style="color:green">已采集</span>
                    <span v-else>未采集</span>
                </template>
            </el-table-column>
            <el-table-column prop="acquisitionTime" label="采集时间" fixed="left"></el-table-column>
            <el-table-column prop="acquisitionVoucher" label="采集凭证" fixed="left">
                <template slot-scope="{row}">
                    <a v-if="row.acquisitionVoucher" :href="row.acquisitionVoucher" target="_blank">
                        <el-image
                        :size="50"
                        :src="row.acquisitionVoucher+'?x-oss-process=image/resize,m_fill,w_64,h_64'"
                        :key="row.id"
                        ></el-image>
                    </a>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" label-width="160px"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                <el-button size="mini" type="warning" @click="handleEdit(row)">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </div>
        <irisCourseStudent-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></irisCourseStudent-detail>
    </div>
</template>
<script>
import irisCourseStudentApi from '@/api/bus/irisCourseStudent'
import registrationFeeApi from "@/api/bus/registrationFee";
import irisCourseStudentDetail from "./irisCourseStudent-detail";
export default {
    name:"busIrisCourseStudentList",
    data(){
        return {
            queryModel: {
                schoolClassId: "",
                name:"",
                sex:"",
                payTime: "",
                acquisitionTime:"",
                payName:"",
                status:"",
            },
            loading: false,
            sexList:[{
                lable:"男",
                value:"1"
            },
            {
                lable:"女",
                value:"2"
            }],
            payNameList:[{
                lable:"微信",
                value:"10"
            },
            {
                lable:"支付宝",
                value:"20"
            }],
            statusList:[{
                lable:"未采集",
                value:"10"
            },
            {
                lable:"已采集",
                value:"20"
            }],
            tableData: [],
            multipleSelection: [],
            schoolClassList: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10, 20, 30],
            showModal: false,
            downloadLoading: false,
        }
    },
    created() {
        var self = this;

        registrationFeeApi.schoolClassList().then(response => {
        var jsonData = response.data;
        this.schoolClassList = jsonData.data;
        });
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("schoolClassId", this.queryModel.schoolClassId);
            formData.append("name", this.queryModel.name);
            formData.append("payName", this.queryModel.payName);
            formData.append("status", this.queryModel.status);
            formData.append("payTimeRanges", this.queryModel.payTime);
            formData.append("acquisitionTimeRanges", this.queryModel.acquisitionTime);

            irisCourseStudentApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleEdit(record) {
            this.modalTitle = "编辑";
            this.operation = "edit";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        downloadXls() {
            //导出
            var self = this;
            self.downloadLoading = true;
            self.loading = true;
            self.loadingText = "数据导出中，请稍后...";

            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("schoolClassId", this.queryModel.schoolClassId);
            formData.append("name", this.queryModel.name);
            formData.append("payName", this.queryModel.payName);
            formData.append("status", this.queryModel.status);
            formData.append("payTimeRanges", this.queryModel.payTime);
            formData.append("acquisitionTimeRanges", this.queryModel.acquisitionTime);

            var timestamp = new Date().getTime();
            formData.append("timestamp", timestamp);

            formData.append("pageSize", 2000);

            irisCourseStudentApi.exportXls(formData).then(function(response) {
                var jsonData = response.data;
                self.downloadLoading = false;
                self.loading = false;
                if (jsonData.result) {
                //下载有错误信息提示的报表
                self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                    duration: 60000,
                    type: "success"
                });
                } else {
                self.$message.error(jsonData.message);
                }
            });
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;

            if (retObj.result) {
                var retData = retObj.data;

                this.changePage(this.pageIndex);

            }
        }
    },
    mounted() {
        this.changePage(1);
    },
      components: {
        "irisCourseStudent-detail": irisCourseStudentDetail
    },
}
</script>
<style scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.route-list {
  text-align: left;
}
</style>